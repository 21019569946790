<template>
  <div>
    <h4 class="text-primary mb-0">
      {{ $t('Explore Our Gallery') }}
    </h4>
    <b-row v-if="latestImages.length">
      <!-- latest photo loop -->
      <b-col
        v-for="data in latestImages"
        :key="data.id"
        md="4"
        cols="4"
        class="profile-latest-img"
        @click="selectedImg = data.original_url"
      >
        <b-link>
          <b-img-lazy
            :src="data.original_url"
            v-b-modal.modal-lg
            :alt="data.original_url.slice(5)"
          />
        </b-link>
      </b-col>
      <!-- latest photo loop -->
    </b-row>
    <b-row v-else>
      <b-col md="4" cols="4" class="profile-latest-img">
        <b-link :to="{ name: 'edit-my-profile' }">
          <b-img-lazy
            style="opacity: 0.3"
            :src="require('@/assets/images/provider/default-gallery.jpg')"
          />
        </b-link>
      </b-col>
    </b-row>
    <b-modal id="modal-lg" ok-only ok-title="Accept" centered size="lg" hide-footer>
      <b-img-lazy class="w-100" :src="selectedImg" />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BLink, BImgLazy, VBModal } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImgLazy,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    latestImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedImg: '',
    }
  },
  mounted() {},
}
</script>
<style lang="scss">
.profile-latest-img {
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  margin: 15px 0;

  &:hover {
    transform: translateY(-4px) scale(1.05);
    z-index: 10;
  }

  img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 1.5rem;
  }
}
</style>
