<template>
  <div class="card pt-5 pb-5">
    <div class="overflow-hidden">
      <div class="col-md-10 col-sm-12 m-auto">
        <div class="row">
          <div class="col-12 d-flex mb-2">
            <div class="profile-img">
              <BImgLazy
                v-if="$store.state.auth.user.profile_image"
                class="profile-img"
                :src="$store.state.auth.user.profile_image + '?' + Math.random()"
                alt="profile image"
              />
              <BImgLazy
                v-else
                class="profile-img"
                :src="require('@/assets/images/provider/noprofile.jpg')"
                alt=""
              />
            </div>
            <div class="ml-auto mt-auto">
              <h4 class="font-weight-bolder">
                {{ $t('Profile View') }}
              </h4>
              <h5 class="text-primary font-weight-light cursor-pointer">
                <b-link :to="{ name: 'edit-my-profile' }"
                  >{{ $t('Edit Profile') }}
                  <FeatherIcon size="18" icon="EditIcon" />
                </b-link>
              </h5>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="row">
              <div class="col-12">
                <h4 class="text-primary font-weight-light">
                  {{ $store.state.auth.user.business_name }}
                </h4>
                <h5 class="font-weight-bold">
                  {{ $store.state.auth.user.name }}
                </h5>
                <p class="text-gray m-0" v-html="$store.state.auth.user.about" />
              </div>
              <div class="col-12">
                <h4
                  v-if="$store.state.auth.user.min_age"
                  class="text-primary font-weight-light mt-2"
                >
                  {{ $t('Age Range') }}
                </h4>
                <h5 v-if="$store.state.auth.user.min_age" class="font-weight-bold">
                  {{
                    $store.state.auth.user.min_age == $store.state.auth.user.max_age
                      ? $store.state.auth.user.min_age
                      : $store.state.auth.user.min_age + ' - ' + $store.state.auth.user.max_age
                  }}
                </h5>
                <h4 class="text-primary font-weight-light mt-2">
                  {{ $t('Follow us On') }}
                </h4>
                <b-form-group
                  label-for="facebook"
                  class="font-weight-bold position-relative"
                  label="Facebook"
                >
                  <span v-if="!$store.state.auth.user.facebook_url" class="input-custom-title">
                    <b-link :to="{ name: 'edit-my-profile' }">
                      {{ $t('Add link') }}
                    </b-link>
                  </span>
                  <b-input-group>
                    <b-form-input
                      id="facebook"
                      v-model="$store.state.auth.user.facebook_url"
                      class="border-0"
                      readonly
                      placeholder="Facebook"
                    />
                    <b-input-group-append v-if="$store.state.auth.user.facebook_url">
                      <b-button
                        v-clipboard:copy="$store.state.auth.user.facebook_url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="flat"
                      >
                        <FeatherIcon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-for="instagram"
                  class="font-weight-bold position-relative"
                  label="Instagram"
                >
                  <span v-if="!$store.state.auth.user.instagram_url" class="input-custom-title">
                    <b-link :to="{ name: 'edit-my-profile' }">
                      {{ $t('Add link') }}
                    </b-link>
                  </span>
                  <b-input-group>
                    <b-form-input
                      id="instagram"
                      v-model="$store.state.auth.user.instagram_url"
                      class="border-0"
                      readonly
                      placeholder="Instagram"
                    />
                    <b-input-group-append v-if="$store.state.auth.user.instagram_url">
                      <b-button
                        v-clipboard:copy="$store.state.auth.user.instagram_url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="flat"
                      >
                        <FeatherIcon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-12">
                <h4 class="text-primary font-weight-light mt-2">
                  {{ $t('Contact Us') }}
                </h4>
                <b-form-group
                  label-for="phone"
                  class="font-weight-bold position-relative"
                  :label="$t('Phone Number')"
                >
                  <span v-if="!$store.state.auth.user.phone" class="input-custom-title">
                    <b-link :to="{ name: 'edit-my-profile' }">
                      {{ $t('Add link') }}
                    </b-link>
                  </span>
                  <b-input-group>
                    <b-form-input
                      id="phone"
                      v-model="$store.state.auth.user.phone"
                      class="border-0"
                      readonly
                      placeholder="+34000000"
                    />
                    <b-input-group-append v-if="$store.state.auth.user.phone">
                      <b-button
                        v-clipboard:copy="$store.state.auth.user.phone"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="flat"
                      >
                        <FeatherIcon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-for="email"
                  class="font-weight-bold position-relative"
                  :label="$t('Email')"
                >
                  <span v-if="!$store.state.auth.user.email" class="input-custom-title">
                    <b-link :to="{ name: 'edit-my-profile' }">
                      {{ $t('Add link') }}
                    </b-link>
                  </span>
                  <b-input-group>
                    <b-form-input
                      id="email"
                      v-model="$store.state.auth.user.email"
                      class="border-0"
                      readonly
                      placeholder="Stephanie@toddl.co"
                    />
                    <b-input-group-append v-if="$store.state.auth.user.email">
                      <b-button
                        v-clipboard:copy="$store.state.auth.user.email"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="flat"
                      >
                        <FeatherIcon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-for="website"
                  class="font-weight-bold position-relative"
                  :label="$t('Website URL')"
                >
                  <span v-if="!$store.state.auth.user.website_url" class="input-custom-title">
                    <b-link :to="{ name: 'edit-my-profile' }">
                      {{ $t('Add link') }}
                    </b-link>
                  </span>
                  <b-input-group>
                    <b-form-input
                      id="website"
                      v-model="$store.state.auth.user.website_url"
                      class="border-0"
                      readonly
                      placeholder="https://www.toddl.co/example"
                    />
                    <b-input-group-append v-if="$store.state.auth.user.website_url">
                      <b-button
                        v-clipboard:copy="$store.state.auth.user.website_url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="flat"
                      >
                        <FeatherIcon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  label-for="profile-link"
                  class="font-weight-bold position-relative"
                  :label="$t('Profile Link')"
                >
                  <b-input-group>
                    <b-form-input
                      id="profile-link"
                      v-model="$store.state.auth.user.profile_link"
                      class="border-0"
                      readonly
                      placeholder="Profile Link"
                    />
                    <b-input-group-append v-if="$store.state.auth.user.profile_link">
                      <b-button
                        v-clipboard:copy="$store.state.auth.user.profile_link"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        variant="flat"
                      >
                        <FeatherIcon icon="CopyIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row">
              <div class="col-12">
                <ProfileGallery :latest-images="$store.state.auth.user.media" />
              </div>
              <div v-if="$store.state.auth.user.categories.length" class="col-12">
                <h4 class="text-primary font-weight-light mt-2 mb-1">
                  {{ $t('What do we offer?') }}
                </h4>
                <div class="d-flex flex-wrap">
                  <span
                    v-for="(cat, i) in $store.state.auth.user.categories"
                    :key="i"
                    class="rounded-pill mr-1 mb-1 font-weight-light"
                    :style="[
                      { padding: '0.5rem !important' },
                      { backgroundColor: colors[Math.floor(Math.random() * colors.length)] },
                    ]"
                    >{{ cat.name }}</span
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="d-flex">
                  <h4 class="text-primary font-weight-light mt-3 mb-1">
                    {{ $t('Our Locations') }}
                  </h4>
                  <b-link
                    v-if="locations.length"
                    class="mt-3 mb-1 ml-auto text-underline"
                    :to="{ name: 'locations' }"
                  >
                    {{ $t('See All') }}
                  </b-link>
                  <b-link
                    v-else
                    class="mt-3 mb-1 ml-auto text-underline"
                    :to="{ name: 'locations' }"
                  >
                    {{ $t('Add Location') }}
                  </b-link>
                </div>
                <div v-for="location in locations" :key="location.id" class="d-flex mb-2">
                  <div class="">
                    <google-map
                      :zoom="11"
                      :draggable="false"
                      :location="{ lat: location.latitude, lng: location.longitude }"
                      :styling="'width:8rem; height:8rem; border-radius:6px;overflow:hidden'"
                      :options="{
                        fullscreenControl: false,
                        zoomControl: false,
                        rotateControl: false,
                        streetViewControl: false,
                        scaleControl: false,
                        mapTypeControl: false,
                      }"
                    />
                  </div>
                  <div class="ml-1">
                    <h5 class="font-weight-bolder">
                      {{ location.name }}
                    </h5>
                    <p>{{ location.details }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BButton,
  BInputGroupAppend,
  BFormGroup,
  BSkeleton,
  BLink,
  BImgLazy,
} from 'bootstrap-vue'

import ToastificationContent from '@/components/ToastificationContent.vue'
import LocationService from '@/services/LocationService'
import ProfileGallery from '@/views/pages/my-profile/ProfileGallery'

export default {
  name: 'MyProfile',
  components: {
    ProfileGallery,
    BImgLazy,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BFormGroup,
    BSkeleton,
    BLink,
  },
  data() {
    return {
      LocationService: new LocationService(),
      colors: ['#F2E17833', '#6BC4C533', '#4B64A533', '#FD838933'],
      locations: [],
    }
  },
  mounted() {
    this.LocationService.index().then(res => {
      this.locations = res.data.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).slice(0, 3)
    })
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Text copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy texts!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style scoped>
.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
}

.form-control[readonly] {
  background-color: #f7f8fa;
}

.input-group {
  background: #f7f8fa;
  border-radius: 6px;
}

.input-custom-title {
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: underline;
  cursor: pointer;
}
</style>
